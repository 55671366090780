<template>
  <div class="sm:c-col-4 md:c-col-11 lg:c-col-10">
    <div
      v-if="email"
      class="c-row"
    >
      <div class="c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col">
        <!-- Título -->
        <div class="c-text-subtitle c-text-gray-400">
          Ative sua conta
        </div>

        <!-- Mensagem -->
        <div class="c-text-b2 c-text-gray-300 ui-mb-4 md:ui-flex-grow">
          Para prosseguir, é necessário ativar sua conta. Enviamos um email com o link para <span class="c-text-primary-400 ui-break-words">{{ email }}</span>
          <br>
          <br>
          Caso não tenha recebido, verifique a pasta de spam ou solicite o reenvio do link no botão abaixo.
        </div>

        <!-- Botões -->
        <div class="c-row">
          <div class="c-col-full md:c-col ui-mt-4">
            <c-button
              @click="resendConfirmationEmail()"
              :disabled="submitted"
              class="ui-whitespace-no-wrap"
              data-cy="resend-confirmation-email-button"
              size="md"
              full
            >
              Reenviar email
            </c-button>
          </div>
          <div class="c-col-full md:c-col ui-mt-4">
            <router-link
              :to="{ name: 'login', query: $route.query }"
              @click.native="notifyEventGoToLogin"
            >
              <c-button
                class="ui-whitespace-no-wrap"
                data-cy="go-to-login-button"
                size="md"
                outline
                full
              >
                Voltar para login
              </c-button>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Ilustração -->
      <div
        id="image-wrapper"
        class="c-col-full md:c-col-4 ui-min-w-0 ui-mx-4 c-d-flex c-vertical-end c-horizontal-center md:c-horizontal-start"
      >
        <img
          class="ui-mt-4 ui--mb-8"
          src="@/assets/images/confirm-email-illustration.svg"
          alt="Imagem de confirmação de email"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';
import { emailDoNotRequireConfirmationError } from '@/utils/errors';

export default {
  components: { CButton },
  data() {
    return {
      email: this.$route.query.email,
      submitted: false,
    };
  },
  mounted() {
    if (!this.email) {
      this.$router.push({
        name: 'login',
        query: this.$route.query,
      });
    }
  },
  methods: {
    async resendConfirmationEmail() {
      this.submitted = true;
      try {
        await this.$services.accounts.resendConfirmationEmail(this.email);
        const seconds = 10;
        this.$toast.show({
          type: 'success',
          text: 'Link de ativação reenviado para o seu email.',
          timeout: seconds * 1000,
        });
        this.$services.events.notifyEmailConfirmationOnLoginActionResendEmail();
      } catch (e) {
        if (e === emailDoNotRequireConfirmationError) {
          this.$toast.show({
            type: 'success',
            text: 'Seu email já está confirmado, faça o login.',
            timeout: 0,
          });
        } else {
          this.submitted = false;
          throw e;
        }
      }
    },
    notifyEventGoToLogin() {
      this.$services.events.notifyEmailConfirmationOnLoginActionBackToLogin();
    },
  },
};
</script>

<style scoped lang="scss">
/* medidas do figma */
#image-wrapper > img {
  width: 207px;
}

@include c-mq(md) {
  #image-wrapper > img {
    width: 276px;
    min-width: 276px; /* não deixa a coluna encolher a imagem */
  }
}

/* não deixa a coluna ficar maior que a imagem */
@include c-mq(md) {
  #image-wrapper {
    max-width: 276px;
  }
}
</style>
